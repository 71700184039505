import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Bill Creedon</h1>
        <p>Software Engineer</p>
      </header>

      <div className="container">
        <div className="bio">
          <p>I convert software designs and specifications into high-functioning code and oversee the integration of individual software solutions to higher-level systems. With over 25 years of experience in developing, testing, and maintaining .NET applications for various domains, including education, finance, and retail. I am also an AWS Certified DevOps Engineer Professional, Solutions Architect, and Developer, with proficiency in using serverless components such as Lambda, S3, DynamoDB, and Athena.</p>
          <p>My core competencies include system integration, business support, technical troubleshooting, and cross-group collaboration. I am passionate about creating and delivering robust and scalable software solutions that meet the needs and expectations of the end-users and stakeholders. I also enjoy learning new technologies and tools that can enhance my skills and improve the quality of my work. Always open to new challenges and opportunities that can help me grow as a professional and as a person.</p>
        </div>
      </div>

      <footer>
        <p><a href='https://www.linkedin.com/in/williamcreedon/' content='https://www.linkedin.com/in/williamcreedon/'>LinkedIn Profile</a></p>
      </footer>
    </div>
  );
}

export default App;

